import type { Planner, PlannerProject, RequestedRelease, SalesforceObject } from "@/types/salesforce";
import type { ProcessingStatus } from "@/utils/processing-status";
import type { EnumToUnion } from "@/types/utils";

export type LabelcampAction = "product creation" | "track creation";
export type OpenPlayAction = "project update";
export type ProjectActivationAction =
  | "master project creation"
  | "project creation"
  | "abortion"
  | "salesforce planner project update"
  | "project existence validation";
export type ShopifyAction = "product creation" | "product cover art uploading";
export type SharepointLibraryAction = "list creation" | "permission assignment";
export type ReleaseCreationAction =
  | "release creation"
  | "abortion"
  | "SF.Planner update"
  | "release existence validation"
  | "SF.PlannerProject get";

export type LogAction =
  | LabelcampAction
  | OpenPlayAction
  | ProjectActivationAction
  | ShopifyAction
  | SharepointLibraryAction
  | ReleaseCreationAction;

export type Log<TAction extends LogAction = LogAction> = TAction extends LogAction
  ? {
      type?: "info" | "warning" | "error";
      timestamp: string;
      action: TAction;
      metadata?: Record<string, any>;
      reason?: string;
    }
  : never;

export type EntityProcessingResult = {
  startDate: string;
  endDate: string;
  log: Log[];
} & (
  | { entityType: SalesforceObject.Planner; entity: Partial<Planner> }
  | { entityType: SalesforceObject.PlannerProject; entity: Partial<PlannerProject> }
  | { entityType: SalesforceObject.RequestedRelease; entity: Partial<RequestedRelease> }
);

export enum MessageType {
  PlannerProjectUpdate = "planner-project-update",
  PlannerDelivered = "planner-delivered",
  PlannerProjectActivation = "planner-project-activation",
  PlannerProjectRoutingNotification = "planner-project-routing-notification",
  SharepointProjectLibrarySync = "sharepoint-project-library-sync",
  PlannerLabelcampProductCreation = "planner-labelcamp-product-creation",
  PlannerShopifyProductCreation = "planner-shopify-product-creation",
  ReleaseCreation = "release-creation",
}

type TransferSuccess = {
  success: true;
  entities: EntityProcessingResult[];
};

type TransferFailure = {
  success: false;
  reason: string;
};

type TransferResult = TransferSuccess | TransferFailure;

export type SalesforceMessage = {
  id: number;
  status: ProcessingStatus.Failed | ProcessingStatus.Finished | ProcessingStatus.FinishedWithWarnings;
  transferResult: TransferResult;
  messageType: MessageType;
  createdAt: string;
  message: Record<string, any>
};

export type Webhook<T extends MessageType = EnumToUnion<MessageType>> = {
  messageType: T;
  endpoint: string;
  object: SalesforceObject;
};

type LabelcampLabelMapping = {
  tenantCode: string;
  openplayLabelId: number;
  labelcampLabelId: string;
};

type WebhookSettings<T extends MessageType> = T extends MessageType.PlannerLabelcampProductCreation
  ? { labelMappings: LabelcampLabelMapping[] }
  : never;

export type WebhookWithSettings<T extends MessageType = EnumToUnion<MessageType>> = {
  webhook: Webhook<T>;
  description?: string;
  details?: string;
  settings: WebhookSettings<T>;
};

export type UpdateWebhookDto = {
  description?: string;
  messageType: MessageType;
  labelMappings?: LabelcampLabelMapping[];
};
