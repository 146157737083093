import type { Log, SharepointLibraryAction } from "@/api/webhooks/types";
import { Button, message, Timeline, Typography } from "antd";
import { Color } from "@/utils";
import "./EntityProcessingTimeline.less";
import cn from "classnames";
import moment from "moment";
import { Copy } from "@/components/common/Icons";
import Icon from "@ant-design/icons";

const { Text } = Typography;

type Props = {
  logs: Log[];
};

const getLogColor = (log: Log) => {
  if (log.type === "error") {
    return Color.Failed;
  }
  if (log.type === "warning") {
    return Color.Warning;
  }
  return Color.Accent;
};

const getSharepointLogMetadata = (log: Log<SharepointLibraryAction>) => (
  <span>
    <span style={{ textTransform: "capitalize" }}>{log.metadata?.list?.type}</span> -{" "}
    <Text code>{log.metadata?.list?.name}</Text>
  </span>
);

const getLogMetadata = (log: Log) => {
  switch (log.action) {
    case "list creation":
    case "permission assignment":
      return getSharepointLogMetadata(log);
    case "product creation":
      if (log.metadata?.productId) {
        return (
          <>
            <span>Product ID:</span> <Text code>{log.metadata?.productId}</Text>
          </>
        );
      }
      return null;

    case "track creation":
      return log.metadata && `${log.metadata.disc}-${log.metadata.track}. ${log.metadata.title}`;
    case "master project creation":
    case "project creation":
      return (
        log.metadata &&
        !log.reason && (
          <>
            <span>Project Number:</span> <Text code>{log.metadata?.response?.project_number}</Text>
          </>
        )
      );
    case "release creation":
      return (
        log.metadata &&
        !log.reason && (
          <>
            <span>UPC:</span> <Text code>{log.metadata?.response?.upc_number}</Text>
          </>
        )
      );
    default:
      return null;
  }
};

export const EntityProcessingTimeline = ({ logs }: Props) => {
  const handleCopy = async (obj: any) => {
    await navigator.clipboard.writeText(JSON.stringify(obj, null, 2));
    message.success("The request has been copied to clipboard");
  };
  return (
    <Timeline className="entity-processing-timeline">
      {logs.map((log) => {
        const metadata = getLogMetadata(log);
        return (
          <Timeline.Item color={getLogColor(log)} key={log.timestamp}>
            <div className="entity-processing-timeline__time">{moment.utc(log.timestamp).format("LTS")}</div>
            <div className="entity-processing-timeline__label">{log.action}</div>
            {metadata && <div className="entity-processing-timeline__metadata">{getLogMetadata(log)}</div>}
            {(log.type === "warning" || log.type === "error") && (
              <div
                className={cn(
                  "entity-processing-timeline__reason",
                  `entity-processing-timeline__reason--${log.type}`
                )}
              >
                {log.reason}
              </div>
            )}
            {log.metadata?.request && (
              <Button
                className="entity-processing-timeline__copy"
                size="small"
                type="text"
                icon={<Icon component={Copy} />}
                onClick={() => handleCopy(log.metadata.request)}
              >
                Copy Request JSON
              </Button>
            )}
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};
