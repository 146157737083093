import { Refresh } from "@/components/common/Icons";
import { IconButton } from "@/components/common/IconButton/IconButton";
import useSWRMutation from "swr/mutation";
import type { SalesforceMessage } from "@/api/webhooks/types";
import { retryWebhook } from "@/api/webhooks";
import { openNotification } from "@/components/common/CommonNotification";

type Props = {
  message: SalesforceMessage;
};

export const RetryWebhookButton = ({ message }: Props) => {
  const { isMutating, trigger } = useSWRMutation(["salesforce-messages"], () => retryWebhook(message.id), {
    onSuccess: () => {
      openNotification({
        message: "The message has been resent",
      });
    },
  });
  return <IconButton onClick={trigger} disabled={isMutating} icon={Refresh} />;
};
