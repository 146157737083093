import type { ColumnsType } from "antd/lib/table/interface";
import type { SalesforceMessage } from "@/api/webhooks/types";
import moment from "moment";
import { ProcessingStatusTag } from "@/components/common/ProcessingStatusTag";
import { getSalesforceMessageTypeLabel } from "@/utils/salesforce-message-type";
import { TagFilter } from "@/components/common/TagFilter";
import { RetryWebhookButton } from "@/components/Webhooks/Activity/RetryWebhookButton";

export const columns: ColumnsType<SalesforceMessage> = [
  {
    title: "Status",
    render: (_, entry) => (
      <TagFilter filters={{ "statuses[]": [entry.status] }}>
        <ProcessingStatusTag status={entry.status} />
      </TagFilter>
    ),
  },
  {
    title: "Action",
    render: (_, entry) => (entry.messageType ? getSalesforceMessageTypeLabel(entry.messageType) : "-"),
  },
  { title: "Finished At", render: (_, entry) => moment.utc(entry.createdAt).format("L, LT (z)") },
  {
    render: (_, entry) =>
      // soapenv:Envelope is present only in refactored messages, that can be resent
      "soapenv:Envelope" in entry.message ? <RetryWebhookButton message={entry} /> : null,
  },
];
