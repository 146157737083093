import { api } from "../core";
import type { PaginatedResponse, Pagination } from "../types";
import type { MessageType, SalesforceMessage, UpdateWebhookDto, Webhook, WebhookWithSettings } from "./types";

const messagesUrl = "/api/salesforce-messages";
const webhooksInfoUrl = "/api/webhook-info";

export const fetchSalesforceMessages = (params: Pagination) =>
  api.get<any, PaginatedResponse<SalesforceMessage>>(messagesUrl, { params });

export const fetchWebhooks = () => api.get<any, Webhook[]>(webhooksInfoUrl);

export const fetchWebhook = (type: MessageType) =>
  api.get<any, WebhookWithSettings>(`${webhooksInfoUrl}/${type}`);

export const updateWebhook = (params: UpdateWebhookDto) => api.put<any, void>(webhooksInfoUrl, params);

export const downloadWebhookLog = (type: MessageType) =>
  api.get<any, Blob>(`${messagesUrl}/${type}/log`, { responseType: "blob" });

export const retryWebhook = (id: number) => api.post<any, void>(`${messagesUrl}/${id}/retry`);
